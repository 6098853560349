<script setup lang="ts">
import { computed, onMounted, reactive, ref, unref, watch } from 'vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { useRoute } from 'vue-router'
import { getMediaById, IMedia } from '@/api'
import Hls from 'hls.js'
import { formatTime } from '@/helpers/'
import Popper from 'vue3-popper'

const route = useRoute()
const mediaId = computed(() => route.params.media as string)
const media = ref<IMedia | null>(null)
const hls = ref()
const containerVideo = ref<HTMLVideoElement | null>(null)
const volume = ref(1)
const encodings = ref([])
const currentBitrate = ref('')

const loadMediaDataAsync = async () => {
  try {
    const { data } = await getMediaById(unref(mediaId))
    media.value = data
    const description = data.description.toString().replace(/\\n/g, '<br>')
    media.value = { ...media.value, ...{ description: JSON.parse(description) } }
    Object.keys(media.value.hls_info).map((key, value) => {
      const bitrate = parseInt(key)
      const url = media.value.hls_info[key].toString()
      if (!isNaN(parseInt(key)) && url.includes('stream')) {
        if (currentBitrate.value === '') {
          currentBitrate.value = bitrate
        }
        encodings.value = { ...encodings.value, ...{ [bitrate]: url } }
      }
      return value
    })
  } catch (error) {
    console.error(error, 'error')
  }
}

const wrapperProgress = ref()
const currentProgress = ref('0px')
const progressWidth = ref(0)
const isPlaying = ref(false)
const isOverLayHidden = ref(true)
const isInfoBarHidden = ref(false)
const overlayTimer = ref()
const infoBarTimer = ref()

const currentTime = ref('00:00')
const durationTime = ref('00:00')

const initPlayer = () => {
  loadMediaDataAsync().then(() => {
    if (Hls.isSupported()) {
      hls.value = new Hls()
    }
    hls.value.loadSource(`http://media.lndata.pro${encodings.value[currentBitrate.value]}`)
    hls.value.attachMedia(document.getElementById('containerVideo'))
    hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
      if (containerVideo.value) {
        containerVideo.value.volume = volume.value
        containerVideo.value.addEventListener('canplay', () => {
          const hasHours = (containerVideo.value.duration / 3600) >= 1.0
          durationTime.value = formatTime(containerVideo.value.duration, hasHours)
        })
        containerVideo.value.addEventListener('play', (e) => {
          isPlaying.value = true
          setTimeout(() => {
            isOverLayHidden.value = true
            isInfoBarHidden.value = true
          }, 1000)
          console.log('Play', e)
        })
        containerVideo.value.addEventListener('pause', () => {
          isPlaying.value = false

          if (overlayTimer.value) {
            clearTimeout(overlayTimer.value)
          }
          if (infoBarTimer.value) {
            clearTimeout(infoBarTimer.value)
          }
          isOverLayHidden.value = false
          isInfoBarHidden.value = false
        })
        containerVideo.value.addEventListener('timeupdate', (e) => {
          if (!containerVideo.value?.duration) {
            return true
          }
          if (progressWidth.value === 0 && wrapperProgress.value.offsetWidth) {
            progressWidth.value = wrapperProgress.value.offsetWidth
          }
          const hasHours = (containerVideo.value.duration / 3600) >= 1.0
          currentTime.value = formatTime(containerVideo.value.currentTime, hasHours)

          const progress = Math.floor(containerVideo.value.currentTime) / Math.floor(containerVideo.value.duration)
          currentProgress.value = Math.floor(progress * progressWidth.value) + 'px'
        })
        const result = containerVideo.value.play()
        result.then(() => {
          console.log('Playing')
        }).catch(_ => {
          isOverLayHidden.value = false
          isInfoBarHidden.value = false
        })
      }
    })
  })
}

const toggleVolume = () => {
  volume.value = Number(!volume.value)
  containerVideo.value.volume = volume.value
}

onMounted(() => {
  initPlayer()
})
watch(mediaId, () => {
  initPlayer()
})
const setTime = (e) => {
  if (progressWidth.value === 0 && wrapperProgress.value.offsetWidth) {
    progressWidth.value = wrapperProgress.value.offsetWidth
  }
  const x = (e.pageX - e.srcElement.offsetLeft) / progressWidth.value
  containerVideo.value.currentTime = x * containerVideo.value.duration
}
const overlayHandler = () => {
  // if (!isPlaying.value) {
  //   return false
  // }
  isOverLayHidden.value = false
  overlayTimer.value = setTimeout(() => {
    isOverLayHidden.value = true
  }, 3000)
}
const infoBarHandler = () => {
  // if (!isPlaying.value) {
  //   return false
  // }
  if (infoBarTimer.value) {
    clearTimeout(infoBarTimer.value)
  }
  isInfoBarHidden.value = false
  infoBarTimer.value = setTimeout(() => {
    if (isPlaying.value) {
      isInfoBarHidden.value = true
    }
  }, 3000)
}
const setBitrate = async (val) => {
  try {
    const newBitrate = encodings.value[val]
    currentBitrate.value = val
    const time = containerVideo.value.currentTime
    await hls.value.loadSource(`http://media.lndata.pro${newBitrate}`)
    containerVideo.value.currentTime = time
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
<div v-if="media" @dblclick="overlayHandler" @click="infoBarHandler" class="player">
  <video playsInline ref="containerVideo" id="containerVideo" class="player-preview__background"/>
  <div class="player-actions" :class="[!isInfoBarHidden ? 'info': '', isOverLayHidden ? 'play' : 'pause']">
    <transition name="fade">
      <div class="player-actions__series-wrapper full-width d-flex" v-if="!isOverLayHidden">
        <div v-for="(series, index) in media.description.episodes_list" :key="series" class="player-actions__series">
          <router-link :to="{name: 'project-player', params: {media: series}}">
            <BaseButton v-if="series === mediaId" class="uppercase" bg="none" border="gradient">Серия {{index + 1}}</BaseButton>
            <BaseButton v-else class="uppercase" bg="none" border="default">Серия {{index + 1}}</BaseButton>
          </router-link>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__button pr-20 d-flex justify-center" v-if="!isOverLayHidden">
        <CircleButton @click="containerVideo.play()" v-if="!isPlaying" class="btn-player__action" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
        <CircleButton @click="containerVideo.pause()" v-else class="btn-player__action" name="pause" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__info" v-if="!isInfoBarHidden">
        <h2>{{media.description.title}} - {{media.description.episode_num}} серия</h2>
        <p class="category">Сериал | 8 серий | реалити | 8+</p>
        <div class="controls mb-20">
          <div class="control-left">
            <CircleButton name="like" :iconWidth="18" :iconHeight="15.96" view-box="0 0 18 15.96"/>
          </div>
          <div class="control-right">
            <CircleButton @click="toggleVolume" :name="volume ? 'volume':'mute'" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <CircleButton name="upload" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <div class="btn_group p-relative">
              <Popper class="popper">
                <CircleButton name="empty">{{currentBitrate}}p</CircleButton>
                <template #content>
                  <CircleButton class="mb-10" :bg="bitrate == currentBitrate ? 'gradient2':'border'" v-for="(encode, bitrate) in encodings" @click="setBitrate(bitrate)" :key="encode" popovertarget="encodes_list" name="empty">{{bitrate}}p</CircleButton>
                </template>
              </Popper>
            </div>
            <router-link v-if="media.description" :to="{name: 'project', params: {id: media?.description.playlist_id}}">
              <CircleButton name="chevron-down" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            </router-link>
          </div>
        </div>
        <div class="progress mb-20">
          <div class="progress-time__current">{{currentTime}}</div>
          <div @click="setTime" ref="wrapperProgress" class="progress-bar">
            <span :style="{width: currentProgress}" class="progress-bar__current" />
          </div>
          <div class="progress-time__ended">{{durationTime}}</div>
        </div>
      </div>
    </transition>
  </div>
</div>
</template>

<style scoped lang="less">
.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &-preview {
    &__background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      transition: opacity 0.5s ease;
    }
  }
  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    transition: all 0.5s ease;
    align-items: center;
    &.info {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      &.pause {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    &__series {
      &-wrapper {
        display: flex;
        overflow-x: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: left;
        position: absolute;
        top: 20px;
        left: 30%;
        width: 100%;
        padding-right: 20px;
      }
      .btn {
        min-width: 100px;
        margin-right: 10px;
      }
    }
    &__info {
      position: absolute;
      bottom: 0;
      left: 30%;
      right: 30%;
      .category {
        font-family: Wix Madefor Display,serif;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        text-transform: uppercase;
      }
      .controls {
        display: flex;
        justify-content: space-between;
        .control {
          &-left {
            .btn {
              margin-right: 10px;
            }
          }
          &-right {
            display: flex;
            & > .btn {
              margin-right: 10px;
              &_group {
                margin-right: 10px;
              }
              .popper {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btn {
                  text-align: center;
                }
              }
            }
            .btn:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .progress {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        font-family: Wix Madefor Display,serif;
        font-size: 8px;
        font-weight: 600;
        line-height: 14px;
        color: #ffffff;
        &-bar {
          width: 100%;
          height: 2px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 2px;
          position: relative;
          cursor: pointer;
          &__current {
            display: inline-flex;
            height: 2px;
            background: linear-gradient(90deg, #9FE870 0%, #3FD9FB 100%);
            position: absolute;
          }
        }
        &-time {
          &__current {
            margin-right: 10px;
          }
          &__ended {
            margin-left: 10px;
          }
        }
      }
    }
    &__button {
      height: 100%;
      align-items: center;
      .btn {
        background: rgba(217, 217, 217, 0.1);
        border: 2px solid rgba(217, 217, 217, 0.1);
      }
    }
  }
}
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width:1024px){
  .player {
    &-actions {
      padding: 20px 0 20px 20px;
      &__info {
        left: 20px;
        right: 20px;
      }
      &__series {
        &-wrapper {
          left: 20px;
        }
      }
    }
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
