<script setup lang="ts">

import router from '@/router'
import BackLine from '@/components/Lines/BackLine.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import CreateAccount2 from '@/components/Register/CreateAccount2.vue'
import ProjectSeriesSlider from '@/components/Sliders/ProjectSeriesSlider.vue'

import BorderText from '@/components/BorderText.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import IconBase from '@/components/Icons/IconBase.vue'
import BackStagePlayer from '@/components/Players/BackStagePlayer.vue'
import { computed, onMounted, ref, unref } from 'vue'
import { useRoute } from 'vue-router'
import { getPlaylistById, IPlaylist } from '@/api'
import PreviewTrailerPlayer from '@/components/Players/PreviewTrailerPlayer.vue'

const route = useRoute()
const playListId = computed(() => route.params.id as string)
const playList = ref<IPlaylist | null>(null)

const loadPlaylistDataAsync = async () => {
  try {
    const { data } = await getPlaylistById(unref(playListId))
    playList.value = data
    const description = playList.value?.description.toString().replace(/\\n/g, '<br>')
    try {
      playList.value.description = JSON.parse(description)
    } catch (e) {
      console.log('JSON parse error')
    }
  } catch (error) {
    console.error(error, 'error')
  }
}

onMounted(() => {
  loadPlaylistDataAsync()
})

</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <ProjectSeriesSlider :playlistMedia="playList?.playlist_media" />
  <BorderText class="pa-10">
    <h2 class="text-gradient weight-500 d-inline-flex">{{playList?.description?.title}}</h2>
    <h6 v-html="playList?.description?.description" />
  </BorderText>
  <BorderText class="pa-10">
    <h2 class="mb-20 weight-500">В ролях:</h2>
    <span class="role pa-10" :key="role" v-for="role in playList?.description?.roles">{{role}}</span>
  </BorderText>
  <div class="trailer px-20 py-10 mt-20">
    <h2>Трейлер</h2>
    <PreviewTrailerPlayer />
  </div>
  <BorderText class="pa-10">
    <h3 class="mb-20">Предложи свою идею...</h3>
    <h6>Стань соавтором новой серии и предложи  идею для съемок!</h6>
    <BaseInput class="mt-10 full-width" type="text" placeholder="Напиши свою идею здесь..."/>
    <BaseButton class="mt-10 large rounded full-width d-flex justify-between" bg="gradient" border="gradient">Отправить <icon-base name="lock" view-box="0 0 12 15" :width="12" :height="15"/></BaseButton>
    <p class="mt-10 text-center text-gradient">только для зарегистрированных пользователей</p>
  </BorderText>
  <div class="votes px-20 py-10">
    <h3 class="mb-20">Проголосуй за лучшую идею</h3>
    <h6 class="mb-20">Голосуй и отслеживай популярность в твоём личном кабинете</h6>
    <BaseButton class="large rounded full-width vote-btn mb-20" bg="none" border="gradient">
      <p class="text">Посетить Японию</p>
      <icon-base name="lock" view-box="0 0 16 18" :width="16" :height="18"/>
    </BaseButton>
    <BaseButton class="large rounded full-width vote-btn mb-20" bg="none" border="gradient">
      <p class="text">Прыгнуть с парашутом</p>
      <icon-base name="lock" view-box="0 0 16 18" :width="16" :height="18"/>
    </BaseButton>
    <BaseButton class="large rounded full-width vote-btn mb-20" bg="none" border="gradient">
      <p class="text">Съесть 1 кг шоколада</p>
      <icon-base name="lock" view-box="0 0 16 18" :width="16" :height="18"/>
    </BaseButton>
    <div class="d-flex justify-center text-center flex-direction-column mt-40">
      <h6 class="text-center">Результаты 1го тура голосования</h6>
      <h6 class="text-gradient d-inline-flex margin-auto">12 июня 2024</h6>
    </div>
    <BorderText bg="bg-gray">
      <h6 class="text-center">Серия с идеей победителя выйдет 20 июля. Победителя ждет приз.</h6>
    </BorderText>
  </div>
  <div class="backstage my-40 px-20">
    <h3 class="mb-20">Бейкстейдж</h3>
    <BackStagePlayer />
  </div>
  <CreateAccount2 />
</template>

<style scoped lang="less">
.role {
  display: inline-block;
  color: #ffffff;
  background: rgb(25,25,25);
  border-radius: 10px;
  margin-right: 10px;
  font-family: Wix Madefor Display, serif;
  margin-bottom: 10px;
}
p {
  font-family: Wix Madefor Display, serif;
  font-size: 14px;
}
.votes {
  .vote {
    &-btn {
      .text {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>
