<script setup lang="ts">
import { onMounted, ref } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { getMediaById, searchMedia } from '@/api'
import Hls from 'hls.js'

const hls = ref()
const containerVideo = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const trailer = ref()
const isOverLayHidden = ref(false)
const overlayTimer = ref()
const loadTrailerDataAsync = async () => {
  try {
    const { data } = await searchMedia('trailermedia')
    const videoList = data.results.filter(el => el.media_type === 'video')
    const videoId = videoList[Math.floor(Math.random() * videoList.length)].friendly_token
    try {
      const { data } = await getMediaById(videoId)
      trailer.value = data
      console.log(trailer.value)
      if (Hls.isSupported()) {
        hls.value = new Hls()
      }
      hls.value.loadSource(trailer.value.hls_info.master_file)
      hls.value.attachMedia(document.getElementById('containerVideo'))
      hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
        containerVideo.value.addEventListener('play', (e) => {
          isPlaying.value = true
          setTimeout(() => {
            isOverLayHidden.value = true
          }, 1000)
        })
        containerVideo.value.addEventListener('pause', () => {
          isPlaying.value = false
          if (overlayTimer.value) {
            clearTimeout(overlayTimer.value)
          }
          isOverLayHidden.value = false
        })
      })
    } catch (e) {
      console.log('Get media error', e)
    }
  } catch (e) {
    console.log('Trailer error', e)
  }
}
const overlayHandler = () => {
  isOverLayHidden.value = false
  overlayTimer.value = setTimeout(() => {
    isOverLayHidden.value = !!isPlaying.value
  }, 3000)
}
onMounted(() => {
  loadTrailerDataAsync()
})
</script>

<template>
  <div @dblclick="overlayHandler" class="player-preview">
    <div v-if="!isOverLayHidden" :class="!isPlaying ? 'player-preview__overlay player-preview__overlay-black':'player-preview__overlay'">
      <CircleButton @click="containerVideo.play()" v-if="!isPlaying" class="btn-player__action play" bg="none" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      <CircleButton @click="containerVideo.pause()" v-else class="btn-player__action play" bg="none" name="pause" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
    </div>
    <video ref="containerVideo" id="containerVideo" class="player-preview__background"/>
  </div>
</template>

<style scoped lang="less">
.player-preview {
  position: relative;
  &__background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    &-black {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
    .bg-none {
      opacity: 1;
      color: #ffffff;
      border: #ffffff solid 2px;
    }
  }
}
</style>
