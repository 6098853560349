<script setup lang="ts">
import { defineProps, nextTick, onMounted, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
import BaseButton from '@/components/Buttons/BaseButton.vue'
const props = defineProps(['catalog'])

</script>
<template>
  <swiper
    slides-per-view="auto"
    :space-between="20"
    class="slider"
    :loop="true"
  >
    <swiper-slide :key="`slide_${index}`" v-for="(slide, index) in props.catalog?.results" class="slide">
      <router-link :to="{name: 'project', params: {id: slide?.url}}" class="full-width">
        <img class="slide__background" :src="slide?.thumbnail" :alt="slide?.description?.title" />
        <div class="slide__wrapper">
          <div class="ribbons">
            <BaseButton class="uppercase" bg="gradient">премьера</BaseButton>
          </div>
          <div class="slide__info">
            <h2 class="slide__info-title">{{slide?.description?.title}}</h2>
            <h5 class="slide__info-description" v-html="slide?.description?.description"/>
            <h6 class="slide__info-category uppercase">Сериал | 8 серий | реалити | 8+</h6>
          </div>
        </div>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<style scoped lang="less">
.slider {
  padding: 20px 10px;
  //height: 75vh;
  .slide {
    width: 90%;
    display: inline-flex;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    &__background {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
